import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
if (process.env.REACT_APP_NODE_ENV === "production") {
  console.log = function () {};
  console.error = function () {};
  console.warn = function () {};
  console.info = function () {};
  console.debug = function () {};
}
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DB,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  dataPath: process.env.REACT_APP_USER_DATA_PATH_REGISTER,
  emailSuffix: process.env.REACT_APP_EMAIL_SUFFIX,
  expayApiUrl: process.env.REACT_APP_EXPAY_API_URL,
};
console.log(firebaseConfig);
console.log("Firebase Config:", firebaseConfig);
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
console.log("Firebase Database:", database);
console.log("this is db");
console.log(database);
console.log(auth);
export { database, auth, firebaseConfig };
