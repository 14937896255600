import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { firebaseConfig } from "../../config/firebase";

export default function Login() {
  const navigate = useNavigate();
  const { currentUser, login, setError } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Add state for error message
  localStorage.removeItem("refreshed");

  useEffect(() => {
    if (currentUser) {
      navigate("/profile");
    }
  }, [currentUser, navigate]);

  async function handleFormSubmit(e) {
    e.preventDefault();
    try {
      setErrorMessage(""); // Clear previous error message
      setLoading(true);
      await login(email, password);
      navigate("/profile");
    } catch (error) {
      setErrorMessage("Failed to login, Please check your card number and password"); // Set error message
    }
    setLoading(false);
  }

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"> {/* Dark background */}
      <div className="max-w-md w-full space-y-8">
        <br />
        <br />
        <h2 className="mt-4 text-3xl text-center tracking-tight font-light">
          Login to your account
        </h2>
        <form className="mt-8 space-y-6" onSubmit={handleFormSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="mb-4">
              <input
                id="email-address"
                name="email"
                type="text"
                autoComplete="text"
                required
                className="placeholder-white appearance-none rounded-md block w-full px-4 py-3 placeholder-gray-500 bg-gray-800 border border-gray-600 text-white text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter last 4 digits of your card"
                onChange={(e) =>
                  setEmail(`${e.target.value}${firebaseConfig.emailSuffix}`)
                }
              />
            </div>
            <div className="mb-4">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="placeholder-white appearance-none rounded-md block w-full px-4 py-3 placeholder-gray-500 bg-gray-800 border border-gray-600 text-white text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-700 hover:bg-sky-900"
            >
              {loading ? "Logging in..." : "Login"}
            </button>
          </div>

          {errorMessage && (  // Conditionally render error message
            <div className="mt-4 text-red-500 text-center">
              {errorMessage}
            </div>
          )}

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link to="/register" className="text-blue-400 hover:underline">
                Don't have an account? Register
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
