import * as React from 'react';
import { useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'; 
import Logout from "../accounts/Logout";
import { Link } from 'react-router-dom';

// Styled components
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  padding: 0,
  height: '64px',
}));

const Logo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
}));

// Custom Button Styles
const CustomButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: 'transparent',
  borderRadius: '4px',
  padding: theme.spacing(1, 2),
  transition: 'background-color 0.3s, color 0.3s',
  '&:hover': {
    backgroundColor: '#f0f0f0',
    color: 'black',
  },
}));

const SignUpButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#1976d2',
  borderRadius: '4px',
  padding: theme.spacing(1, 2),
  '&:hover': {
    backgroundColor: '#145a8a',
  },
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const { currentUser, logout } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleSignOut = async () => {
    await logout();
    navigate('/home');
    setOpen(false);
  };

  const handleNavigation = (path) => () => {
    navigate(path);
    setOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ 
        boxShadow: 0, 
        bgcolor: 'white', 
        top: 0, 
        left: 0, 
        right: 0, 
      }}
    >
      <StyledToolbar>
        {/* Logo */}
        <Logo>
          <Link to="/home">
            <img 
              src="/logo.png" 
              alt="Logo" 
              style={{ 
                height: '200px', 
                width: 'auto',
              }} 
            />
          </Link>
        </Logo>

        {/* Navigation Buttons */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
          <CustomButton 
            variant="text" 
            size="small" 
            onClick={() => navigate('/home')}
          >
            Home
          </CustomButton>
          {currentUser ? (
            <>
              <CustomButton 
                variant="text" 
                size="small" 
                onClick={() => navigate('/profile')}
              >
                My Account
              </CustomButton>
              <SignUpButton  
                variant="text" 
                size="small" 
                onClick={() => {handleSignOut()}}
              >
                Logout
              </SignUpButton>
              {modal && <Logout modal={modal} setModal={setModal} />}
            </>
          ) : (
            <>
              <SignUpButton 
                variant="text" 
                size="small" 
                onClick={() => navigate('/login')}
              >
                Login
              </SignUpButton>
            </>
          )}
        </Box>

        {/* Mobile Drawer */}
        <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
          <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
            <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseRoundedIcon />
                </IconButton>
              </Box>
              <Divider sx={{ my: 3 }} />
              <MenuItem onClick={handleNavigation('/home')}>
                <CustomButton variant="text" fullWidth>
                  Home
                </CustomButton>
              </MenuItem>
              {currentUser ? (
                <>
                  <MenuItem onClick={handleNavigation('/profile')}>
                    <CustomButton variant="text" fullWidth>
                      My Account
                    </CustomButton>
                  </MenuItem>
                  <MenuItem>
                    <SignUpButton  
                      variant="text" 
                      fullWidth 
                      onClick={handleSignOut}
                    >
                      Logout
                    </SignUpButton>
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem onClick={handleNavigation('/login')}>
                    <SignUpButton  variant="text" fullWidth>
                      Login
                    </SignUpButton>
                  </MenuItem>
                </>
              )}
            </Box>
          </Drawer>
        </Box>
      </StyledToolbar>
    </AppBar>
  );
}
