import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { CreditCard, AccessTime, Shield, AttachMoney, Android, Apple } from '@mui/icons-material';
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';

// Styled components
const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundImage: 'url(/path/to/back.png)', // Fallback image
  backgroundSize: 'cover',
}));

const BackgroundVideo = styled('video')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: -1,
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)', // Transparent black
  zIndex: 1,
}));

const Content = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  textAlign: 'center',
  color: theme.palette.common.white,
}));

const BenefitsSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#1E1E1E', // Dark background for the section
  color: theme.palette.common.white,
  padding: theme.spacing(6, 0),
}));

const AboutContent = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}));

const HeroButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1), // Add margin around buttons
  fontWeight: 600,
  padding: theme.spacing(1, 2),
  fontSize: '0.8rem', // Smaller font size
}));

const AboutText = styled(Box)(({ theme }) => ({
  maxWidth: '80vw',
  padding: theme.spacing(2),
  textAlign: 'left',
}));

const BenefitsContainer = styled(Container)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const BenefitCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#2C2C2C', // Slightly lighter than the section background
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  boxShadow: theme.shadows[3],
  textAlign: 'left',
  color: theme.palette.common.white,
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  fontSize: '2rem',
  color: theme.palette.primary.main,
}));

const BenefitText = styled(Box)(({ theme }) => ({
  flex: 1,
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

const DownloadSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#1E1E1E', // Keep the dark background for consistency
  color: theme.palette.common.white,
  padding: theme.spacing(6, 0),
  textAlign: 'center',
}));

const StoreButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2), // Add spacing between the buttons
  flexWrap: 'wrap', // Ensures the buttons wrap if the screen is too small
}));

const StoreButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0), // Add top and bottom margin for smaller screens
  fontWeight: 600,
  padding: theme.spacing(1, 3),
  fontSize: '0.9rem',
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '220px', // Set a fixed width for the buttons
  '& svg': {
    marginRight: theme.spacing(1),
  },
}));

export default function HomePage() {
  const [showAnimation, setShowAnimation] = useState(false);
  const aboutSectionRef = useRef(null);
  const appSectionRef = useRef(null);
  const footerRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false); // State to manage button clicks

  useEffect(() => {
    const flowText = localStorage.getItem('flowText');
    if (!flowText || flowText === 'true') {
      setShowAnimation(true);
      localStorage.setItem('flowText', 'false');
    }
  }, []);

  const scrollToAbout = () => {
    aboutSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToFooter = () => {
    if (isScrolling) return; // Prevent multiple clicks
    setIsScrolling(true); // Set to true when starting to scroll
    footerRef.current?.scrollIntoView({ behavior: 'smooth' });

    setTimeout(() => setIsScrolling(false), 1000); // Reset scrolling state
  };

  const scrollToApps = () => {
    appSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <HeroSection>
        <BackgroundVideo src="/back1.mp4" preload="auto" autoPlay muted loop>
          Your browser does not support the video tag.
        </BackgroundVideo>
        <Overlay />
        <Container>
          <Content>
            <Typography variant="h3" component="h1" sx={{ mb: 2 }}>
              Expay Financial
            </Typography>
            <Typography variant="h5" component="h1" sx={{ mb: 2 }}>
              {showAnimation ? (
                <TypeAnimation
                  sequence={[ 'Simplify your payroll and financial management with our innovative prepaid credit card solutions' ]}
                  wrapper="span"
                  speed={60}
                  cursor={false}
                  repeat={1}
                />
              ) : (
                'Simplify your payroll and financial management with our innovative prepaid credit card solutions'
              )}
            </Typography>
            <HeroButton variant="contained" color="primary" component={Link} to="/login">
              My Account
            </HeroButton>
            <HeroButton variant="contained" color="primary" onClick={scrollToAbout}>
              About Us
            </HeroButton>
            <HeroButton variant="contained" color="primary" onClick={scrollToApps}>
              Download our apps
            </HeroButton>
            <HeroButton variant="contained" color="primary" onClick={scrollToFooter}>
              Contact Us
            </HeroButton>
          </Content>
        </Container>
      </HeroSection>

      <BenefitsSection ref={aboutSectionRef}>
        <Container>
          <Title variant="h4" component="h2">
            Why choose us?
          </Title>
          <AboutContent>
            <AboutText>
              <Typography variant="body1">
                At Expay Financial, we are dedicated to enhancing financial inclusion across Canada. Our partnership with VISA allows us to provide innovative digital payment solutions tailored for various industries.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                We focus on modernizing the check cashing process, offering businesses a seamless transition to digital transactions, ultimately saving costs and improving efficiency.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Our Prepaid Credit Card Services empower companies to handle payroll without the complexities of traditional banking, ensuring secure and convenient payment options for employees and contractors.
              </Typography>
            </AboutText>
          </AboutContent>
        </Container>

        <Container>
          <BenefitsContainer>
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
              <BenefitCard>
                <IconWrapper>
                  <AttachMoney />
                </IconWrapper>
                <BenefitText>
                  <Typography variant="h6">Cost Effective</Typography>
                  <Typography variant="body2">Send a list instead of writing multiple checks</Typography>
                </BenefitText>
              </BenefitCard>
            </motion.div>
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
              <BenefitCard>
                <IconWrapper>
                  <AccessTime />
                </IconWrapper>
                <BenefitText>
                  <Typography variant="h6">Time Efficient</Typography>
                  <Typography variant="body2">Employees get paid on time and avoid extra trips to cash their checks</Typography>
                </BenefitText>
              </BenefitCard>
            </motion.div>
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
              <BenefitCard>
                <IconWrapper>
                  <Shield />
                </IconWrapper>
                <BenefitText>
                  <Typography variant="h6">Secure</Typography>
                  <Typography variant="body2">Reliable and cashless</Typography>
                </BenefitText>
              </BenefitCard>
            </motion.div>
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
              <BenefitCard>
                <IconWrapper>
                  <CreditCard />
                </IconWrapper>
                <BenefitText>
                  <Typography variant="h6">Convenient</Typography>
                  <Typography variant="body2">No bank account is required, and the card is reloadable</Typography>
                </BenefitText>
              </BenefitCard>
            </motion.div>
          </BenefitsContainer>
        </Container>
      </BenefitsSection>

      <DownloadSection>
        <Container>
          <Typography variant="h4" component="h2" sx={{ mb: 4 }}>
            Download Our Apps
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Manage your account, track transactions, and enjoy full control from anywhere. Download the ExPay Financial app today!
          </Typography>
          <StoreButtonsContainer>
            <StoreButton
              ref={appSectionRef}
              variant="contained"
              color="primary"
              href="https://play.google.com/store/apps/details?id=your_android_app_link"
              startIcon={<Android />}
            >
              Download for Android
            </StoreButton>

            <StoreButton
              variant="contained"
              color="primary"
              href="https://apps.apple.com/app/idyour_ios_app_link"
              startIcon={<Apple />}
            >
              Download for iOS
            </StoreButton>
          </StoreButtonsContainer>
        </Container>

        <Box ref={footerRef}>
          {/* Your footer content goes here */}
        
        </Box>
      </DownloadSection>
    </>
  );
}
