import React from "react";

export default function ExpayPrivacyPolicy() {
  return (
    <div style={{ padding: "20px 40px", fontFamily: "'Poppins', sans-serif", backgroundColor: "#f9f9f9" }}>
      <br />
      <br />

      <h1
        style={{
          textAlign: "center",
          fontSize: "2rem",
          color: "#2c3e50",
          fontWeight: "600",
          marginBottom: "30px",
        }}
      >
        Privacy Policy
      </h1>

      <p
        style={{
          fontSize: "1rem",
          lineHeight: "1.6",
          color: "#34495e",
          marginBottom: "20px",
        }}
      >
        At Expay Financial, we respect your privacy and are committed to protecting it through compliance with this Privacy Policy ("Policy"). This Policy describes the types of information we may collect from you or that you may provide ("Personal Information") on the www.expay.ca ("Website") and any of its related products and services (collectively, "Services"). It also explains our practices for collecting, using, maintaining, protecting, and disclosing that information.
      </p>
      <p
        style={{
          fontSize: "1rem",
          lineHeight: "1.6",
          color: "#34495e",
          marginBottom: "30px",
        }}
      >
        This Policy outlines your choices regarding the use of your Personal Information and how you can access and update it. By accessing and using our Website and Services, you acknowledge that you have read, understood, and agreed to the terms of this Policy.
      </p>

      <h2
        style={{
          fontSize: "1.5rem",
          color: "#2980b9",
          fontWeight: "500",
          marginBottom: "15px",
        }}
      >
        1. Scope
      </h2>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        This Policy applies to all visitors, users, and others who access the Website or Services. If you are using our Services on behalf of a business or entity, you confirm that you have the authority to accept this Policy on their behalf.
      </p>

      <h2
        style={{
          fontSize: "1.5rem",
          color: "#2980b9",
          fontWeight: "500",
          marginBottom: "15px",
        }}
      >
        2. Information We Collect
      </h2>

      <h3
        style={{
          fontSize: "1.25rem",
          color: "#8e44ad",
          marginBottom: "10px",
        }}
      >
        2.1 Automatic Collection of Information
      </h3>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "10px" }}>
        When you visit our website, certain information may be automatically collected, including:
      </p>
      <ol style={{ paddingLeft: "20px", fontSize: "1rem", color: "#34495e", marginBottom: "20px",listStyleType: "decimal" }}>
        <li>Your IP address, browser type, and operating system</li>
        <li>Pages you visit and time spent on those pages</li>
        <li>Referring website details and language preferences</li>
        <li>Other usage statistics</li>
      </ol>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        This information helps us identify usage trends, prevent abuse, and enhance our Services. This data does not identify you personally.
      </p>

      <h3
        style={{
          fontSize: "1.25rem",
          color: "#8e44ad",
          marginBottom: "10px",
        }}
      >
        2.2 Collection of Personal Information
      </h3>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "10px" }}>
        We may collect the following types of Personal Information when you interact with our website:
      </p>
      <ol style={{ paddingLeft: "20px", fontSize: "1rem", color: "#34495e", marginBottom: "20px",listStyleType: "decimal" }}>
        <li>Contact information: Name, email address, phone number</li>
        <li>Device location data: Geolocation data such as latitude and longitude</li>
      </ol>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        Providing this information is voluntary, but certain features may not be available without it.
      </p>

      <h3
        style={{
          fontSize: "1.25rem",
          color: "#8e44ad",
          marginBottom: "10px",
        }}
      >
        2.3 Information About Children
      </h3>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        We do not knowingly collect Personal Information from individuals under the age of 18. If you believe a child under 18 has provided information to us, please contact us at [customerservice@expay.ca], and we will promptly remove the information.
      </p>

      <h2
        style={{
          fontSize: "1.5rem",
          color: "#2980b9",
          fontWeight: "500",
          marginBottom: "15px",
        }}
      >
        3. Use of Collected Information
      </h2>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        We use collected information for the following purposes:
      </p>
      <ol style={{ paddingLeft: "20px", fontSize: "1rem", color: "#34495e", marginBottom: "20px",listStyleType: "decimal" }}>
        <li>Improving user experience and Website functionality</li>
        <li>Operating and delivering Services</li>
        <li>Requesting feedback to enhance our offerings</li>
      </ol>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        We may process your information based on:
      </p>
      <ol style={{ paddingLeft: "20px", fontSize: "1rem", color: "#34495e", marginBottom: "30px",listStyleType: "decimal" }}>
        <li>Your consent</li>
        <li>Contractual obligations</li>
        <li>Compliance with legal obligations</li>
        <li>Legitimate business interests</li>
      </ol>

      <h2
        style={{
          fontSize: "1.5rem",
          color: "#2980b9",
          fontWeight: "500",
          marginBottom: "15px",
        }}
      >
        4. Sharing and Disclosure
      </h2>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        We may share your information with trusted Service Providers to facilitate operations, provided they adhere to privacy standards consistent with this Policy. We will never sell or share your Personal Information with unaffiliated third parties for their marketing purposes.
      </p>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        Additionally, we may disclose information:
      </p>
      <ol style={{ paddingLeft: "20px", fontSize: "1rem", color: "#34495e", marginBottom: "30px",listStyleType: "decimal" }}>
        <li>To comply with legal obligations</li>
        <li>To protect rights, property, or safety</li>
        <li>To investigate potential violations or fraud</li>
      </ol>

      <h2
        style={{
          fontSize: "1.5rem",
          color: "#2980b9",
          fontWeight: "500",
          marginBottom: "15px",
        }}
      >
        5. Data Retention
      </h2>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        We retain Personal Information as long as necessary to fulfill the purposes outlined in this Policy, comply with legal obligations, and resolve disputes. Once the retention period expires, your data will be securely deleted.
      </p>

      <h2
        style={{
          fontSize: "1.5rem",
          color: "#2980b9",
          fontWeight: "500",
          marginBottom: "15px",
        }}
      >
        6. Cookies and Analytics
      </h2>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        Our website uses cookies and analytics tools to enhance user experience and monitor Website performance. You can manage cookie preferences through your browser settings.
      </p>

      <h2
        style={{
          fontSize: "1.5rem",
          color: "#2980b9",
          fontWeight: "500",
          marginBottom: "15px",
        }}
      >
        7. Information Security
      </h2>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        We implement robust security measures to protect your information. However, no system can guarantee absolute security, and you agree to accept the inherent risks associated with data transmission over the Internet.
      </p>

      <h2
        style={{
          fontSize: "1.5rem",
          color: "#2980b9",
          fontWeight: "500",
          marginBottom: "15px",
        }}
      >
        8. Data Breach Notification
      </h2>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        If a data breach occurs, we will notify affected individuals as required by applicable laws and take appropriate measures to address the issue promptly.
      </p>

      <h2
        style={{
          fontSize: "1.5rem",
          color: "#2980b9",
          fontWeight: "500",
          marginBottom: "15px",
        }}
      >
        9. Changes to This Policy
      </h2>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        We may update this Policy from time to time. The latest version will always be available on our website, and any significant changes will be communicated to you through email or Website notifications.
      </p>

      <h2
        style={{
          fontSize: "1.5rem",
          color: "#2980b9",
          fontWeight: "500",
          marginBottom: "15px",
        }}
      >
        10. Contact Us
      </h2>
      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "20px" }}>
        For questions or concerns about this Policy, please contact us at:
      </p>
      <p
        style={{
          fontSize: "1rem",
          lineHeight: "1.6",
          color: "#34495e",
          marginBottom: "20px",
          fontWeight: "bold",
        }}
      >
        <strong>Expay Financial</strong>
        <br />
        Website: <a href="http://www.expay.ca">www.expay.ca</a>
        <br />
        Email: <a href="mailto:customerservice@expay.ca">customerservice@expay.ca</a>
      </p>

      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        We will address your concerns promptly and in accordance with applicable privacy laws.
      </p>

      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e", marginBottom: "30px" }}>
        <strong>Effective Date: July 2nd, 2024</strong>
      </p>

      <p style={{ fontSize: "1rem", lineHeight: "1.6", color: "#34495e" }}>
        This updated Policy provides transparency and complies with global privacy standards, including GDPR, CCPA, and other relevant regulations.
      </p>
    </div>
  );
}
