// BouncingLoader.js
import React from 'react';
import { styled } from '@mui/material/styles';

const BouncingLoaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 9999,
});

const Dot = styled('div')({
  width: '15px',
  height: '15px',
  borderRadius: '50%',
  margin: '0 5px',
  backgroundColor: 'darkblue',
  animation: 'bounce 0.6s infinite alternate',

  '@keyframes bounce': {
    '0%': {
      transform: 'translateY(0)',
      backgroundColor: 'darkblue', // Dark blue
    },
    '100%': {
      transform: 'translateY(-20px)',
      backgroundColor: 'lightblue', // Light blue
    },
  },

  // Use nth-child for different dots
  '&:nth-child(1)': {
    animationDelay: '0s',
  },
  '&:nth-child(2)': {
    animationDelay: '0.1s',
  },
  '&:nth-child(3)': {
    animationDelay: '0.2s',
  },
});

const BouncingLoader = () => {
  return (
    <BouncingLoaderContainer>
      <Dot />
      <Dot />
      <Dot />
    </BouncingLoaderContainer>
  );
};

export default BouncingLoader;
