import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

// Custom Button-like Component for Privacy Policy
function PrivacyPolicyButton({ onClick }) {
  return (

        <Typography variant="body2" sx={{ color: 'text.secondary' }} onClick={onClick}>
        <u>View our Privacy Policy</u>
      </Typography>
  );
}

// Custom Button-like Component for Account Deletion Request Form
function AccountDeletionRequestFormButton({ onClick }) {
  return (
  
        <Typography variant="body2" sx={{ color: 'text.secondary' }} onClick={onClick}>
        <u>Request Account Deletion</u>
      </Typography>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
      {'Copyright © '}
      <Typography color="text.secondary" component="span">
        ExPay Financial Canada
      </Typography>
      &nbsp;{new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const navigate = useNavigate(); // Using React Router's useNavigate for navigation

  // Preload the components manually
  React.useEffect(() => {
    // Triggering the import() for preloading the components

    import('../accounts/privacyPolicy');
    import('../accounts/submitForm');
  }, []);

  // Handle the Privacy Policy link click
  const handlePrivacyPolicyClick = () => {
    navigate('/privacyPolicy'); // Navigates to the Privacy Policy page
  };

  // Handler for navigation to the Account Deletion Request Form
  const handleAccountDeletionRequestClick = () => {
    navigate('/submitForm'); // Navigates to the Account Deletion Request Form page
  };

  return (
    <Box
      sx={{
        color: '#ffffff',
        textAlign: 'center',
        fontSize: '14px',
        borderTop: '1px solid black', // Add a black top border
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', // Add shadow effect
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 2, sm: 4 },
          py: { xs: 2, sm: 4 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>
            <Copyright />
          </div>
          <Stack
            direction="column"
            alignItems="center"
            spacing={0.5}
            sx={{ textAlign: { xs: 'center', sm: 'left' } }}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Email: customerservice@expay.ca
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Phone/WhatsApp: +1 (888) 606 9729
            </Typography>
          </Stack>
  
          <Stack
            direction="row"
            spacing={1}
            useFlexGap
            sx={{ justifyContent: 'center', color: 'text.secondary', mt: { xs: 0.5, sm: 0 } }}
          >

            
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
  {/* Align the buttons closer by adjusting the spacing */}

           <PrivacyPolicyButton onClick={handlePrivacyPolicyClick} />
            <AccountDeletionRequestFormButton onClick={handleAccountDeletionRequestClick} />

</Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
