import React, { useEffect, useState } from "react";

export default function SubmitForm() {
  const [iframeHeight, setIframeHeight] = useState("0px");

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        const evntData = event.data;
        if (evntData && typeof evntData === "string") {
          const zfIfrmData = evntData.split("|");
          if (zfIfrmData.length === 2 || zfIfrmData.length === 3) {
            const zfPerma = zfIfrmData[0];
            const newHeight = `${parseInt(zfIfrmData[1], 10) + 15}px`;

            const iframe = document
              .getElementById("zf_div_RPXSpnoonaXYc0IqBpn2_qRvQfxqvFEUdBBhKJHwYGo")
              ?.querySelector("iframe");

            if (
              iframe &&
              iframe.src.includes("formperma") &&
              iframe.src.includes(zfPerma)
            ) {
              if (iframe.style.height !== newHeight) {
                if (zfIfrmData.length === 3) {
                  iframe.scrollIntoView();
                  setTimeout(() => {
                    setIframeHeight(newHeight);
                  }, 500);
                } else {
                  setIframeHeight(newHeight);
                }
              }
            }
          }
        }
      } catch (e) {
        console.error("Error processing iframe message:", e);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const divContainer = document.getElementById(
      "zf_div_RPXSpnoonaXYc0IqBpn2_qRvQfxqvFEUdBBhKJHwYGo"
    );
    if (!divContainer) {
      console.error("Container div not found!");
      return;
    }

    // Check if iframe already exists to avoid duplications
    let iframe = divContainer.querySelector("iframe");
    if (!iframe) {
      iframe = document.createElement("iframe");
      iframe.style.width = "100%";
      iframe.style.height = iframeHeight;
      iframe.src =
        "https://forms.zohopublic.com/virtualoffice22839/form/ContactUs/formperma/RPXSpnoonaXYc0IqBpn2_qRvQfxqvFEUdBBhKJHwYGo?zf_rszfm=1"; // Replace with your actual iframe source URL
      iframe.frameBorder = "0";
      iframe.allowTransparency = "true";
      divContainer.appendChild(iframe);
    } else {
      iframe.style.height = iframeHeight; // Update the height dynamically
    }
  }, [iframeHeight]);

  return (
    <div
      id="zf_div_RPXSpnoonaXYc0IqBpn2_qRvQfxqvFEUdBBhKJHwYGo"
      style={{
        border: "1px solid #ccc",
        padding: "20px",
        borderRadius: "8px",
        background: "#f9f9f9",
      }}
    >
      <h3>Fill Out the Form Below</h3>
    </div>
  );
}
